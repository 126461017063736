#rating {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

#rating-rate,
#rating-star {
  display: inline-block;
}
