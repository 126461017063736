@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;600&display=swap");

:root {
  /* Color Palette */
  --white: #fefefe;
  --red: #fe7968;
  --green: #68c3a3;
  --yellow: #f9c62e;
  --light-gray: #d4d4d8;
  --gray: #707070;
  --dark-gray: #52525b;
  --black: #18181b;
}

body {
  /*
    Below are the default properties of the UI.
   */
  background-color: var(--white);
  color: var(--black);
  font-family: "Outfit";
}

h6 {
  /* 
    H6 headers must be in all uppercase letters
    based on the style guide.
   */
  text-transform: uppercase;
}
