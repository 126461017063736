/**
* Adjusted width from 80% to 100% of .footer_contents, .footer, and .footer_copyright
* Changes made to accommodate actual size of grid and not conform to only 80% size of grid
*/
.footer {
	width: 100%;
	padding-block: 50px;
	position: relative;
	margin-top: 10rem;
}

.footer_contents {
	width: 100%;
	margin: 0 auto;
	display: grid;

	/* Reference: https://css-tricks.com/look-ma-no-media-queries-responsive-layouts-using-css-grid/ */
	grid-template-columns: repeat(auto-fit, minmax(240px, 22.5%));
	align-items: flex-start;
	gap: 50px;
}

.footer_copyright {
	width: 100%;
	margin: 0 auto;
	font-size: small;
	color: var(--gray);
}

.footer_contents_explore p {
	line-height: 1.8;
}

.footer_contents_contact p {
	line-height: 1.8;
}

.logo_place {
	height: 30px;
}

.home_button,
.profile_button {
	color: var(--black);
	text-decoration: none;
}

.home_button:hover,
.profile_button:hover {
	color: var(--red);
	transition: opacity 2s ease-in-out;
}
