/* Review (NO Picture) */
#review-no-pic-header {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.review-no-pic-headerChild {
  display: inline-block;
}

/* Review (with Picture) */
#review-with-pic {
  width: 50%;
}

#review-with-pic-header {
  display: inline-flex;
  width: 100%;
}

#review-with-pic-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#review-with-pic-name {
  /* flex-direction: row; */
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

#review-with-pic-rating {
  display: flex;
}
