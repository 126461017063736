.header {
	width: 100%;
	height: 2rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 2.5rem;
	margin-bottom: 2rem;
	margin-top: 1.25rem;
}

.header_logo {
	height: 34px;
}

.header_right_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1.2rem;
}

.header_right_user_container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
}

.header_right_user_container > img {
	width: 36px;
	height: 36px;
	object-fit: cover;
	border-radius: 50%;
}

.header_data {
	text-decoration: none;
	color: var(--black);
}

/* .header_name_ */
